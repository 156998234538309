
.cutting-wrap .cutting-item {
    padding: 5px;
    margin: 10px 0px;
}

.cutting-wrap  .cutting-item:not(:last-child) {
    border-bottom: 1px solid #ced4da;
}

.cutting-wrap  .material {
    font-size: 16px;
    font-weight: 600;
}

.cutting-wrap  .cutting {
    font-size: 15px;
    margin: 6px 0;
}
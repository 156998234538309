.read-mark-list-orders .card-title {
    width: 100%;
}

.read-mark-list-orders .card-header {
    padding-right: 28px;
}

.read-mark-list-orders .form-check-label {
    font-size: 15px;
    line-height: 24px;
}



.list-group-choice-shift li {
    break-inside: avoid-column;
    padding: 6px;

}
.list-group-choice-shift .list-group-item+.list-group-item {
    border-top-width: 1px;
}
.list-group-choice-shift {
    margin: 0 ;
    padding: 0;
}
@media (min-width: 768px) {
    .list-group-choice-shift {
        column-count: 2;
    }
}@media (min-width: 992px) {
    .list-group-choice-shift {
        column-count: 3;
    }
}
@media (min-width: 1400px) {
    .list-group-choice-shift {
        column-count: 4;
    }
}
.item-filed-bonuses-fines{
    position: relative;
    padding: 25px 0;
    border-bottom: 1px solid #ced4da;

}
.item-filed-bonuses-fines .remove-icon{
    position: absolute;
    top: 10px;
    right: 0;
}


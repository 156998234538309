.user-panel {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
}

.image .fas {
    color: #ffffff;
    margin-top: 9px;
}

.sidebar-collapse .user-panel .info {
    display: none;
}

.sidebar-collapse .main-sidebar:not(:hover) .user-panel {
    justify-content: center;
}

.sidebar-collapse .user-panel .image {
    padding: 0;
}

.main_wrapper:not(.sidebar-collapse)
.nav-sidebar
.nav-treeview
> .nav-item
> .nav-link
> .nav-icon {
    margin-left: 0px;
}

.sidebar-mini.sidebar-collapse
.main-sidebar:hover
.nav-sidebar
.nav-treeview
> .nav-item
> .nav-link
> .nav-icon {
    margin-left: 0px;
}

.menu-user-role {
    color: #c2c7d0;
    white-space: pre-wrap;
}

.nav-link.btn.btn-success {
    color: white !important;
}

.nav-link.btn.btn-success .fas {
    margin-right: 10px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

a > div {
    display: none;
    position: relative;
}

a:not(.brand-link):hover > div {
    display: block;
    z-index: 200;
    position: absolute;
    /* top: -20px;
    left: -50px; */
}

.nav-sidebar .nav-item > .nav-link {
    position: relative;
    display: flex;
    align-items: center;
}

@media (max-width: 991px) {
    .main-sidebar,
    .sidebar-.sidebar-collapse:hover {
        margin-left: 0;
    }
}

@keyframes borderColorChange {
    0% {
        box-shadow: 0 0 0 transparent;
        border-color: rgba(255, 0, 0, 0.41);
    }
    100% {
        box-shadow: 0 0 10px #ff0000; /* Change this to your desired color */
        border-color: #ff0000;
    }
}

.payment-block.error {
    animation: borderColorChange 550ms infinite;
    border: 1px solid white;
    cursor: pointer;

}

.payment-block {
    background: white;
    padding: 10px 5px;
    font-size: 14px;
    border-radius: 6px;
}

.payment-block p {
    margin: 0;
    overflow: hidden;
}

.payment-block {
    position: relative;
    text-align: center;
}

p.payment-error {
    color: red;
    font-weight: bolder;
    overflow: hidden;
}

.payment-block .hint {
    position: absolute;
    display: none;
    background: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 0 5px red;
}

.payment-block:hover .hint {
    display: block;
}

/*@media (min-width: 992px) {*/
.sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p {
    width: inherit !important;
    white-space: pre-wrap !important;
}

/*}*/
.payment-additional-wrap ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.payment-additional-wrap ul li{
    padding: 3px 5px;
    border-bottom: 1px solid #ced4da;
    font-size: clamp(12px, 1.5vw, 14px);
    display: flex;
    justify-content: space-between;
    gap: 3px;
}
.production-filter .form-group{
    margin-bottom: 0.5rem;
}
.production-filter .form-group label{
    font-size: 14px;
}

.gap_8{
    gap: 8px;
}
.card-body-production table th,
.card-body-production table td{
    padding: 8px !important;
}
.text_line{
    display: -webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
}
.card-body-production table td{
    font-size: 15px;
    vertical-align: middle;
}
.change_order_stage_production_number_container{
    min-width: fit-content !important;
}
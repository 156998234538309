.modal-bg {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  /* overflow: scroll; */
}

.modal-wrapper {
  max-width: 800px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.9);
  background: #fff;
  color: #000;
  position: relative;
  z-index: 2005;
  border-radius: 10px;
  max-height: 90vh;
  overflow-y: scroll;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* line-height: 1.8; */
  position: relative;
  color: #141414;
}

.close-modal {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;

  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 1200;
}

@media screen and (min-width: 400px) {
  .modal-wrapper {
    width: 100%;
  }
}


.cut-list-checkbox {
    max-height: calc(80vh - 200px);
    overflow: auto; /* Показывает скролл только при необходимости */
    scrollbar-width: thin; /* Для Firefox - делает полосу прокрутки тонкой */
}

.cut-list-checkbox::-webkit-scrollbar {
    width: 8px; /* Ширина полосы прокрутки для Webkit-браузеров */
}

.cut-list-checkbox::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* Цвет полосы прокрутки */
}
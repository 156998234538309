/*filter*/
.goods-filter-container {
    display: grid;
    gap: 15px;
}
.goods-filter-container  label{
    padding-top: 0;
}
.goods-filter-row {
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
    gap: 20px;
}

.css-wsp0cs-MultiValueGeneric {
    font-size: 10px !important;
}

.goods-filter-row .form-input {
    height: revert;
    padding: 9px 10px;
    border-radius: 4px;
}

.goods-filter-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    flex-wrap: wrap;
}

.goods-filter-buttons button {
    margin: 0;
    width: max-content;
    outline: none;
    box-shadow: 0 0 0 transparent;
    font-size: 12px !important;
    text-transform: uppercase;
}

/*goods */


.material-selected-goods {
    display: grid;
    border-radius: 4px;
    border: 1px solid #d9d8e1;
    margin: 40px 0;
}

.material-selected-goods .goods-item-wrap .goods-info button.btn.btn-add-goods {
    margin: 0;
    font-size: 12px !important;
    padding: 5px !important;
}

.material-selected-goods .goods-item-wrap .goods-info {
    display: grid;
    grid-template-columns: 50px  1fr auto;
    padding: 5px 10px;
    gap: 15px;
    align-items: center;
}

.material-selected-goods .goods-item-wrap:nth-child(even) {
    background: rgb(66 98 255 / 4%);
}

.material-selected-goods .goods-item-wrap.goods-added .goods-info {
    background: #56c08066;
}

.goods-item-wrap .description {
    font-size: 12px;
}

.goods-item-wrap .description .title {
    color: black;
    font-weight: bold;
    font-size: 14px;
}

.goods-detail-wrap {
    border-top: 1px solid var(--border);
    padding: 15px 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.goods-detail-wrap .title-sub {
    color: black;
    font-weight: 500;
    margin-right: 6px;
}

.goods-detail-wrap .value-sub {
    color: #9a98ae;
}

.goods-detail-option {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.goods-band-connected .form-input-checkbox-label:before {
    background: white;
}

.goods-info svg,
.goods-info img {
    display: block;
    margin: 0 auto;
    max-height: 50px;
    width: 100%;
}

.goods-detail-wrap table th,
.goods-detail-wrap table td {
    border: 1px solid #f0f0f3;
    font-size: 13px !important;
}

.goods-detail-prices table.material-price-table th {
    padding-left: 8px !important;
    text-align: left;
}


.goods-detail-wrap .drop-label-firm .item {
    display: flex;
}

.goods-stock-wrap-scrolled {
    max-height: 250px;
    overflow-y: auto;
}

table.stock-table {
    border: 1px solid #D6D6DF;
    border-collapse: collapse;
}

table.material-price-table {
    border: 1px solid #D6D6DF;
    border-collapse: collapse;
}

.goods-connected-band-list {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 15px;
}

label.disabled-field.form-input-checkbox-label:before {
    background: transparent;
    border: none;
    content: '';
}

label.disabled-field.form-input-checkbox-label .form-input-checkbox:not(:checked) + span:after {
    content: '';
}

label.disabled-field.form-input-checkbox-label .form-input-checkbox:checked + span:after {
    content: ' ✔ ';
    color: var(--green);
}

@media (min-width: 992px) {
    .goods-item-wrap.open .goods-info {
        grid-template-columns: 150px 1fr 80px 40px;
    }

    .goods-item-wrap.open .goods-info svg,
    .goods-item-wrap.open .goods-info img {
        max-height: 150px;
    }

}

.detail-actions {
    text-align: center;
}

.detail-actions .btn {
    width: max-content;
    font-size: 14px !important;
    margin: 0 auto;
}

.goods-detail-prices table.material-price-table thead {
    background: #f0f0f3;
}

.folder-wrap .field {
    margin: 6px 0;
}

.folder-wrap .field-root label {
    font-weight: 500;
    color: var(--gray-violet);
}

.folder-wrap .form-input-checkbox:checked + span:after {
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
}

.folder-wrap {
    display: grid;
    justify-content: space-between;
    row-gap: 10px;
}

@media (min-width: 576px) {
    .folder-wrap {
        grid-template-columns: repeat(2, calc(100% / 2 - 10px));
    }

    .goods-filter-row {
        grid-template-columns: repeat(2, calc(100% / 2 - 10px));
    }
}

@media (min-width: 1200px) {
    .folder-wrap {
        grid-template-columns: repeat(3, calc(100% / 3 - 10px));
    }

    .goods-filter-row {
        grid-template-columns: repeat(3, calc(100% / 3 - 20px));
    }
}

@media (min-width: 1440px) {
    .folder-wrap {
        grid-template-columns: repeat(4, calc(100% / 4 - 20px));
    }
}


/*container */
.material-selected-filter-page .pagination_container_main {
    justify-content: center;
}

.material-selected-filter-page .header .close-modal path {
}

.material-selected-filter-page .header .close-modal {
    padding: 0 !important;
}

.material-selected-filter-page .header h2 {
    margin: 0;
}

.material-selected-filter-page .header {
    padding: 0 !important;
    margin-bottom: 10px;
}

.btn-switch-material.reverse {
    background: var(--gray-violet);
    padding: 5px 10px;

}

.btn-switch-material.reverse path {
    stroke: white;
}

.goods-filter-header {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.goods-filter-header .folders {
    display: flex;
    align-items: center;
    font-size: 17px;
    gap: 8px;
    cursor: pointer;
    margin-left: auto;
}

.page-container.material-selected-filter-page.packed-content {
    padding-top: 5px;
}

.blink {
    animation: 450ms linear infinite blink;
}

@keyframes blink {
    50% {
        /*opacity: 0.7;*/
        background: #4262ff;
    }
}

table.material-price-table th, table.material-price-table td {
    padding: 5px !important;
}

.material-selected-filter-tabs-wrap {
    /*overflow-x: auto;*/
    /*border-bottom: 1px solid #d9d8e1;*/
    margin-bottom: 0px;
}

.material-selected-filter-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    /*border-bottom: 1px solid;*/
    width: max-content;
    min-width: 100%;
    margin: 0;
}

.material-selected-filter-tabs .nav_item.active {
    color: var(--blue);
    font-size: 14px;
}

.material-selected-filter-tabs .nav_item.active:before {
    content: '';
    display: block;
    height: 2px;
    background: var(--blue);
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
}

.material-selected-filter-tabs .nav_item {
    display: block;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    position: relative;

}

.marked_include_text.title {
    color: var(--blue) !important;
}
.marked_include_text {
    color: black !important;
}
label.form-input-checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
}

.file-manager-add-file .close-modal path {
    stroke: black !important;
    margin-left: auto;
}

.file-manager-header {
    text-align: right;
}

.file-manager-add-file {
    border-bottom: 0.5px solid #cdccd7 !important;
    padding-bottom: 25px;
    margin-bottom: 25px;
}

button.file-manager-btn__add {
    margin-left: auto;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    padding: 12px 15px;
    margin-bottom: 20px;
}

.file-manager__container {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 30px;
}

.list-files {
    margin: 20px 0px 0px 0px;
    width: 100%;
}


.list-files > div {
    display: flex;
    align-items: center;
    padding: 5px 10px;
}

.list-files .count,
.list-files a {
    font-size: 20px;
    margin-right: 6px;
}

.list-files .count {
    color: black;

}

.list-files svg path {
    stroke: red !important;
}

.added-files-name p {
    margin-bottom: 4px;
    text-transform: uppercase;
    color: green;
    margin-top: 0px;
}

.added-files-name .wrap span {
    margin-right: 8px;
}

.list-files a {
    color: #050038;
    font-weight: 400;
    text-decoration: none;
    margin-right: auto;
    max-width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-files > div {
    border-bottom: 0.5px solid #cdccd7 !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
}

.list-files > div:hover {
    background-color: rgba(66, 98, 255, .1);
}

.modal.fade.show {
    display: block!important;
}
.input__wrapper {
    width: 100%;
    position: relative;
    margin: 15px 0;
    text-align: center;
}

.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.input__file-icon-wrapper {
    height: 60px;
    width: 60px;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #d9d8e1;
}

.input__file-button-text {
    line-height: 1;
    margin-top: 1px;
}

.input__file-button {
    width: 100%;
    max-width: 100%;
    height: 60px;
    background: transparent;
    color: black;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
    border: 1px solid #d9d8e1;
}
.input__file-icon-wrapper svg {
    width: 45px;
    height: 45px;
}
.upload_file_project_form {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.import_projects_change_data_s_container {
    width: 100%;
}
.import_projects_change_data_item_info {
    width: 69%;
}
.import_projects_change_data_item_form {
    width: 30%;
}
.import_projects_change_data_item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 19px;
    border-bottom: 1px solid #f0f0f3;
}
.import_projects_change_data_title {
    margin: 19px 0;
    padding-bottom: 0;
    border-bottom: 1px solid #f0f0f3;
}
.import_projects_change_data_item_info_item {
    margin-bottom: 10px;
}
.import_projects_change_data_item_info_item:last-child {
    margin-bottom: 19px;
}
.import_projects_change_data_item_info_item > b {
    width: 154px;
    display: inline-block;
    opacity: 1;
}
.import_projects_change_data_items {
    margin-bottom: 45px;
}
.add-material.upload_file_project_form .create-project{
    display: block !important;
}
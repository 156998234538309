.shift-action {
    display: flex;
    gap: 8px;
    align-items: center;
}

.list-group-shift-plane {
    list-style: none;
    padding: 0;
    margin: 0;
}
.list-group-shift-plane > li:not(:last-child){
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.services_ready_list_by_shift_payment ul:nth-child(even) {
    background-color: white;
}
.services_ready_list_by_shift_payment ul:nth-child(odd) {
    background-color: #f2f2f26e;
}
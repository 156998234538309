.modal.fade.show {
    display: block!important;
}
.view_programm_item {
    border-bottom: 1px solid lightgrey;
    padding: 5px;
    margin-bottom: 10px;
}
.view_programm_close_button {
    cursor: pointer;
}
.view_programm_container {
    max-height: 500px;
}
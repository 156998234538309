.production-task-nav-container li {
  width: 25%;
  text-align: center;
  text-transform: uppercase;
}
.production-task-nav-container li a {
  cursor: pointer !important;
}
.production-task-action * {
  cursor: pointer !important;
}
.production-task-action {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.production-task-action i {
  margin-right: 4px;
}
.production-task-action:not(:last-child) {
  margin-bottom: 15px;
}
.production_task_item_open {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.action-container {
  display: flex;
  align-items: center;
}
.production-task-action:not(:last-child) {
  margin-bottom: 0;
}
.open-modal {
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.open-modal:hover {
  font-size: 18px;
}
.change_order_stage_production_number_container {
  position: absolute;
  padding: 15px;
  background-color: lightgray;
  z-index: 9999;
  min-width: 300px;
  display: flex;
  flex-flow: column;
  align-items: stretch;
}
.dwopdown {
  z-index: 2000;
}

@media screen and (max-width: 1340px) {
  td > div {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  tr {
    margin-bottom: 20px;
  }
}

.change_order_stage_production_number_container_close {
  position: absolute;
  top: 4px;
  right: 9px;
  color: red;
}

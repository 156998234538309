.bg-lightgray {
    background-color: rgba(0, 0, 0, 0.05);
}
.bg-lightgray02 {
    background-color: rgba(0, 0, 0, 0.02);
}

.order-detail-pdf-container {
    text-align: right;
}

.orders_status_change_container {
    position: absolute;
    background: #f4f6f9;
    height: auto;
    flex-flow: column;
    justify-content: flex-start;
    width: 281px;
    z-index: 100;
    padding: 10px;
    top: 35px;
    right: 100%;
    left: 100%;
    transform: translate(-100%, 0);
    border: 1px solid lightgrey;
    max-width: 100vw;
    display: none;
}

.orders_status_change_container.active {
    display: flex;
}

.order_status_container {
    position: relative;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.order_status_container .fas {
    margin-left: 9px;
}

.change_status_variant:not(:last-child) {
    margin-bottom: 9px;
}

.change_status_variant span {
    cursor: pointer;
}

.change_status_variant span:hover {
    color: #007bff;
}

td {
    position: relative;
}

.orders_status_change_container hr {
    margin-top: 5px;
    margin-bottom: 9px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
}

.orders_action_icon_span {
    margin-left: 5px;
}

.order_detail_timeline_container {
    position: absolute;
    right: 30px;
    background-color: #e0dddd;
    padding: 15px;
    min-width: 340px;
    z-index: 999;
}

.order_detail_timeline_container > .text-right {
    position: absolute;
    top: 4px;
    right: 9px;
}

.order_detail_timeline_container .table {
    margin-bottom: 0;
}

.message_pop_up {
    min-height: 340px;
}

.order_open_service_link {
    margin-right: 4px;
}

.print_all_documents_container {
    background: #17a2b8;
    position: absolute;
    width: 100%;
    right: 0;
    top: calc(100% - 0px);
    z-index: 9;
    padding: 10px;
    display: none;
    color: white;
    font-size: 14px;
    font-weight: 300;
    max-width: 350px;
    border-radius: 5px;
}

.print_all_documents_container label {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

.print_all_documents_container label input {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.print_all_documents_container label span {
    font-weight: normal;
}

.print_all_documents_container.open {
    display: block;
}
.order-control-btn .btn,
.order-control-btn .print_all_documents_main_container{
    height: 100% !important;
}
.print_all_documents_main_container {
    position: relative;
    height: 100%;
}
.order-detail-print-gl .print_all_documents_container{
    width: max-content;
    max-width: 280px;
    text-align: left;
    background: #14a2b8;
}
.order-detail-print-gl .print_all_documents_container .item{
    font-size: 16px;
    cursor: pointer;
    margin: 3px 0px;
}

.change-personal {
    position: relative;
}

.change-personal .client-search {
    position: absolute;
    top: 100%;
    width: 450px;
    z-index: 4;
    background: #f5f5f5;
    padding: 10px;
    border: 1px solid #ccc;
    display: none;
    padding: 10px 5px;
    max-height: 300px;
    overflow-y: auto;
}

.change-personal .client-search.show {
    display: block;
}

.change-personal .client-search input {
    width: 100%;
    display: block;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
}

.change-personal .client-search .close {
    cursor: pointer;
    font-size: 18px;
    margin-top: -5px;
    margin-bottom: 10px;
}

.change-personal .client-search th,
.change-personal .client-search td {
    padding: 5px !important;
}

.change-personal .client-search input:focus {
    outline: none;
}

.change-personal .client-search {
    width: 240px;
}

@media (min-width: 568px) {
    .change-personal .client-search {
        width: 450px;
    }
}

.order-detail-pdf__items {
    display: grid;
    grid-template-columns: repeat(2, calc(100% / 2 - 15px));
    gap: 20px;
}
@media (min-width: 992px) {
    .order-detail-pdf__items {
        justify-content: flex-end;
        grid-template-columns: repeat(3, minmax(120px, auto));
    }
}

.order-detail-pdf__items .btn.btn-app.bg-info {
    height: auto;
    padding: 5px 10px;
    margin: 0;
    transform: scale(1);
    transition: transform 0.950ms;
    cursor: pointer;
}
@media (min-width: 992px) {
    .order-detail-pdf__items .btn.btn-app.bg-info:hover {
        transform: scale(1.2);
    }
}

.order-detail-pdf__items .btn.btn-app.bg-info .txt {
    /*margin-top: auto;*/
    line-height: 14px;
    display: inline-block;
}

@media (min-width: 1340px) {
    .paymount-status {
        justify-content: center;
    }

}
    .paymount-status select {
        position: absolute;
        opacity: 0;
        z-index: -1;
        top: calc(-100% - 10px);
    }

    .paymount-status {
        position: relative;
        width: 100%;
        cursor: pointer;
    }

    .paymount-status strong {
        cursor: pointer;
    }

    .paymount-status:hover select {
        opacity: 1;
        z-index: 1;
    }
/*}*/
.paymount-status{
    flex-wrap: nowrap;
}

.checkbox-label__input {
    position: relative;
    padding-left: 25px;
}
.checkbox-label__input label{
    margin-bottom: 0px;
}
.checkbox-label__input input[type="checkbox"] {
    display: none;
}

.checkbox-label__input input:checked + label:after {
    content: '✔';
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 2px;
    top: 0px;
}

.checkbox-label__input label:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0px;
    top: 3px;
    border-radius: 3px;
    border: 1px solid #cccccc;
    cursor: pointer;
    /*background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M18.125 10V16.7083C18.125 17.2167 17.9231 17.7042 17.5636 18.0636C17.2042 18.4231 16.7167 18.625 16.2083 18.625H2.79167C2.28334 18.625 1.79582 18.4231 1.43638 18.0636C1.07693 17.7042 0.875 17.2167 0.875 16.7083V3.29167C0.875 2.78334 1.07693 2.29582 1.43638 1.93638C1.79582 1.57693 2.28334 1.375 2.79167 1.375H13.3333' stroke='%239A98AE' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");*/
}

.cutting-card-settings-print > item {
    margin-bottom: 15px;
}

.cutting-card-settings-print .item {
    margin-bottom: 20px;
}

.cutting-card-settings-print label {
    font-size: 16px;
    color: black;
    font-weight: 400;
}

.cutting-card-settings-print .item.level_2 {
    margin-left: 25px;
}

.cutting-card-settings-print .item.level_3 {
    margin-left: 50px;
}


.toggle-pill input[type="checkbox"]{
    display: none;
}
.toggle-pill + label{
    margin-left: 8px;
}
.toggle-pill input[type="checkbox"] + label {
    display: block;
    position: relative;
    width: 3em;
    height: 1.6em;

    /*margin-bottom: 20px;*/
    border-radius: 1em;
    background: #f3f3f3;
    box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-transition: background 0.1s ease-in-out;
    transition: background 0.1s ease-in-out;
}
.toggle-pill input[type="checkbox"] + label:before {
    content: "";
    display: block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 1em;
    background: #50565a;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.toggle-pill input[type="checkbox"]:checked + label {
    background: #fff;
}
.toggle-pill input[type="checkbox"]:checked + label:before {
    box-shadow: 0 0 0 transparent;
    left: 1.6em;
    background: green;
}
.disabled{
    opacity: 0.5;
    pointer-events: none;
}
.btn-app{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}
.news-wrap .new-item {
    margin-bottom: 22px;
}

.news-wrap .new-item .card_header .icon-arrow.arrow-open {
    transform: translateY(-50%) rotate(180deg);
}
.news-wrap .new-item .card_header .icon-arrow  {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    cursor: pointer;
}
.news-wrap .new-item .card_header .arrow-top {
    transform: translateY(-50%) rotate(90deg);
}
.news-wrap .new-item .card_header .time{
    font-size: 13px;
    color: #777;
    font-weight: 400;
}
.news-wrap .new-item .card_header  .title{
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 25px;
    width: 100%;
}
.news-wrap .new-item .card_header {
    position: relative;
    padding: 15px 45px 15px 15px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;

}
.news-wrap .new-item .card_body{
    padding: 15px;
}
.new-item-section{
    margin-top: 25px;
}
.new-item-section h5{
    font-size: 16px;
}
@media (min-width: 1200px) {
    .news-wrap .new-item .card_header {
        padding: 20px 45px 20px 20px;
        font-size: 22px;
        font-weight: 600;
    }
    .news-wrap .new-item .card_body{
        padding: 25px;
    }
    .new-item-section h5{
        font-size: 20px;
    }
}

.social-links .social-media_link {
    display: flex;
    align-items: center;
    max-width: 50px;
}

.social-links .base64_qr_link {
    display: flex;
    align-items: center;
    max-width: 120px;
}
.social-links .item{
    display: flex;
    align-items: flex-start;
    gap: 15px;
}
.items-center{
    align-items: center;
}
.news-wrap .card_body{
    border-top: 1px solid rgba(0,0,0,.125);;
}
.news-wrap .card_body ul{
    padding-left: 25px;
}
.news-wrap img{
    max-width: 100%;
}
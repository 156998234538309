.clients_stroke_styles {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}
.clients_stroke_styles i {
    margin: 0 5px;
}
.clients_stroke_styles a {
    opacity: 1;
}

@media screen and (max-width: 1340px) {
    .clients_stroke_styles{justify-content: flex-end;} 
}
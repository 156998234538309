.report-filter-row > div {
    width: 100%;
}

@media screen and (min-width: 676px) {
    .report-filter-row > div {
        width: 48%; /* Занимает 33% ширины экрана */
    }
}

@media screen and (min-width: 992px) {
    .report-filter-row > div {
        width: calc(100% / 5 - 15px); /* Занимает 33% ширины экрана */
    }
}
:not(.layout-fixed) .main-sidebar {
    min-height: 100vh;
    height: 100% !important;
    position: fixed;
}
.lang-selector .nav-link {
    padding: 0 15px;
    height: auto;
}
.dropdown-menu.dropdown-menu-sm.dropdown-menu-right .media {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
}
.dropdown-menu.dropdown-menu-lg.dropdown-menu-right .media > i {
    width: 30px;
    font-size: 30px;
    color: #28a745;
}
.dropdown-item{
    white-space: normal;
}
.login-header{
    margin: 25px 15px 25px 15px;
    text-align: right;
}
.login-header .select-custom{
    margin-left: auto;
}
.select-custom {
    position: relative;
    width: max-content;
    margin: 0px 8px;

}
.select-custom li.disabled {
    pointer-events: none;
    opacity: 0.5;
}
.select__head {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
}

.select__head svg {
    margin-right: 6px;
}

.select__body {
    text-transform: uppercase;
    right: 0;
    left: 0;
    text-align: center;
    position: absolute;
    top: 100%;
    background: white;
    border-radius: 4px;
    z-index: 3;
    list-style: none;
    padding: 5px;
    font-size: 14px;
    margin: 0px;
    box-shadow: 0px 0px 5px rgb(217 217 219);
}

.select__body li {
    cursor: pointer;
    font-size: 16px;
    padding: 2px;
}

.select__body li .disabled {
    pointer-events: none;
    opacity: 0.4;
}

.main-sidebar{
    z-index: 99999;
}
.sidebar-mini.sidebar-collapse .main-sidebar:not(:hover) .nav-link{
    font-size: 0;
}
